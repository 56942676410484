import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ManagerRoute from "./routes/ManagerRoute";
import ResidentRoute from "./routes/ResidentRoute";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAuth from "./hooks/useAuth";
import PersistLogin from "./services/PersistLogin";

// Components
import WithFixedSidebar from "./components/layout/WithFixedSidebar";
import { AuthProvider } from "./contexts/AuthProvider";
import { NotificationsProvider } from "./contexts/NotificationsProvider";

// general pages
import Homepage from "./pages/HomePage/HomePage";
import ConnectingPage from "./pages/ConnectingPage/ConnectingPage";
import Loginpage from "./pages/LoginPage/LoginPage";
import ManagerSignupPage from "./pages/SignupPage/ManagerSignupPage/ManagerSignupPage";
import ResidentSignupPage from "./pages/SignupPage/ResidentSignupPage/ResidentSignupPage";
import SuccessRegsiterPage from "./pages/SuccessRegisterPage/SuccessRegisterPage";
import YearPage from "./pages/Management/YearPage/YearPage";
import ErrorPage from "./pages/ErrorPage/ErrorPage";

const DescriptionPage = lazy(() =>
  import("./pages/DescriptionPage/DescriptionPage")
);
const PasswordReset = lazy(() => import("./pages/PasswordReset/PasswordReset"));
const PasswordUpdatePage = lazy(() =>
  import("./pages/PassordUpdatePage/PasswordUpdatePage")
);
const TermsPage = lazy(() => import("./pages/TermsPage/TermsPage"));

const ContactPage = lazy(() => import("./pages/ContactPage/ContactPage"));

const DetailledDesriptionPage = lazy(() =>
  import("./pages/DetailledDescriptionPage/DetailledDesriptionPage")
);

// manager pages
const ManagerYears = lazy(() =>
  import("./pages/Management/YearsPage/ManagerYears")
);
const RealTimePage = lazy(() =>
  import("./pages/Management/RealtimePage/RealtimePage")
);
const DataTracking = lazy(() =>
  import("./pages/Management/DataTracking/DataTracking")
);
const YearDetailPage = lazy(() =>
  import("./pages/Management/YearDetailPage/YearDetailPage")
);

const YearParameters = lazy(() =>
  import("./pages/Management/YearParameters/YearParameters")
);

const ValidationStoryPage = lazy(() =>
  import("./pages/Management/ValidationStoryPage/ValidationStoryPage")
);

const ManagerNotificationPage = lazy(() =>
  import("./pages/Management/NotificationsPage/ManagerNotificationPage")
);

const TimePlannerPage = lazy(() =>
  import("./pages/Management/WeekCreatorPage/TimePlannerPage")
);

const ManagerCalendar = lazy(() =>
  import("./pages/Management/Agenda/Calendar/ManagerCalendarPage")
);

const WeekDispatcherPage = lazy(() =>
  import("./pages/Management/Agenda/WeekDispatcher/WeekDispatcherPage")
);

const WeekCreatorPage = lazy(() =>
  import("./pages/Management/Agenda/WeekCreator/WeekCreatorPage")
);

// resident pages
const SearchPage = lazy(() => import("./pages/Resident/SearchPage/SearchPage"));
const TimerPage = lazy(() => import("./pages/Resident/TimerPage/TimerPage"));
const ResidentStatisticsPage = lazy(() =>
  import("./pages/Resident/Statistics/Statistics")
);
const DataManagement = lazy(() =>
  import("./pages/Resident/DataManagement/DataManagement")
);
const YearsResidentPage = lazy(() =>
  import("./pages/Resident/YearsResident/YearsResidentPage")
);

const ParametersPage = lazy(() =>
  import("./pages/Resident/Parameters/ParametersPage")
);

const ResidentNotificationPage = lazy(() =>
  import("./pages/Resident/NotificationsPage/ResidentNotificationPage")
);

function App() {
  const { authentication } = useAuth();
  return (
    <div className="App">
      <AuthProvider>
        <NotificationsProvider>
          <BrowserRouter>
            <Suspense
              fallback={() => {
                return;
              }}
            >
              <WithFixedSidebar>
                <Routes>
                  {/* public routes */}
                  <Route path="/" element={<Homepage />} />

                  <Route path="/description" element={<DescriptionPage />} />

                  <Route
                    path="/jobDetail/:id"
                    element={<DetailledDesriptionPage />}
                  />

                  <Route path="/connecting" element={<ConnectingPage />} />

                  <Route
                    path="/managerSignup"
                    element={<ManagerSignupPage />}
                  />

                  <Route
                    path="/residentSignup"
                    element={<ResidentSignupPage />}
                  />

                  <Route path="/success" element={<SuccessRegsiterPage />} />

                  <Route path="/login" element={<Loginpage />} />

                  <Route path="/passwordReset" element={<PasswordReset />} />

                  <Route
                    path="/passwordUpdatePage/:token"
                    element={<PasswordUpdatePage />}
                  />

                  <Route path="/terms" element={<TermsPage />} />

                  <Route path="/contactUs" element={<ContactPage />} />

                  <Route path="/404" element={<ErrorPage />} />

                  <Route element={<PersistLogin />}>
                    {/* manager routes */}
                    <Route element={<ManagerRoute />}>
                      <Route path="/manager_years" element={<ManagerYears />} />
                      <Route path="/year_detail" element={<YearDetailPage />} />
                      <Route path="/year" element={<YearPage />} />
                      <Route
                        path="/yearParameters"
                        element={<YearParameters />}
                      />
                      <Route path="/realtime" element={<RealTimePage />} />
                      <Route path="/dataTracking" element={<DataTracking />} />
                      <Route
                        path="/validationStory"
                        element={<ValidationStoryPage />}
                      />
                      <Route
                        path="/managerNotifications"
                        element={<ManagerNotificationPage />}
                      />
                      <Route
                        path="/timePlanner"
                        element={<TimePlannerPage />}
                      />
                      <Route
                        path="/managerCalendar"
                        element={<ManagerCalendar />}
                      />
                      <Route
                        path="/weekDispatcher"
                        element={<WeekDispatcherPage />}
                      />

                      <Route
                        path="/weekCreator"
                        element={<WeekCreatorPage />}
                      />
                    </Route>

                    {/* resident routes */}
                    <Route element={<ResidentRoute />}>
                      <Route path="/timer" element={<TimerPage />} />
                      <Route path="/search" element={<SearchPage />} />
                      <Route
                        path="/resident_years"
                        element={<YearsResidentPage />}
                      />
                      <Route
                        path="/dataManagement"
                        element={<DataManagement />}
                      />
                      <Route
                        path="/residentStatistic"
                        element={<ResidentStatisticsPage />}
                      />

                      <Route
                        path="/residentParameters"
                        element={<ParametersPage />}
                      />
                      <Route
                        path="/residentNotification"
                        element={<ResidentNotificationPage />}
                      />
                    </Route>
                  </Route>
                </Routes>
              </WithFixedSidebar>
              <ToastContainer />
            </Suspense>
          </BrowserRouter>
        </NotificationsProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
