import axios from "axios";
import { YEARS_API, TIMESHEET_API, MANAGER_API } from "../config";

/**
 * Get all years related as manager or resident of the current user.
 */
function findAll() {
  const info = {
    method: "get",
    url: "managers/years/getManagersYears",
  };
  return info;
}

/**
 * Create a year.
 * @returns {obg}
 */
function create() {
  const info = {
    method: "post",
    url: "managers/years/create",
  };
  return info;
}

/**
 * Update a year.
 * @returns {obg}
 */
function update() {
  const info = {
    method: "put",
    url: "managers/years/update",
  };
  return info;
}

/**
 * @returns
 */
function getYearByToken() {
  const info = {
    method: "post",
    url: "residents/findByYearByToken",
  };
  return info;
}

function addYear() {
  const info = {
    method: "post",
    url: "residents/years/joinYear",
  };
  return info;
}

/**
 *
 * @returns array
 */
function findResidentYears() {
  const info = {
    method: "get",
    url: "years/getResidentYears",
  };
  return info;
}

function excel(id, callback) {
  return axios({
    url: TIMESHEET_API + "timesheets/ExcelGenerator/" + id,
    method: "GET",
    responseType: "blob",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Horaire.xlsx");
    document.body.appendChild(link);
    link.click();
    callback();
  });
}

function excelAsManager(yearId, residentId, residentName, callback) {
  return axios({
    url: MANAGER_API + "/ExcelGenerator/" + yearId + "/" + residentId,
    method: "GET",
    responseType: "blob",
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Horaire-" + residentName + ".xlsx");
    document.body.appendChild(link);
    link.click();
    callback();
  });
}

/**
 * Invite a guest.
 * @returns {obg}
 */
function inviteGuest() {
  const info = {
    method: "post",
    url: "managers/years/addManager",
  };
  return info;
}

function fetchYearManagers() {
  const info = {
    method: "get",
    url: "managers/getYearManagers/",
  };
  return info;
}

function UpdateYearResidentRelation() {
  const info = {
    method: "post",
    url: "managers/residentValidation",
  };
  return info;
}

function deleteYearResidentRelation() {
  const info = {
    method: "delete",
    url: "managers/residentValidation/",
  };
  return info;
}

/**
 *
 * @returns Month that have to be validate
 */
function fetchPeriod() {
  const info = {
    method: "get",
    url: "managers/validationList/",
  };
  return info;
}

/**
 *
 * @returns Update validation period
 */
function updatePeriod() {
  const info = {
    method: "post",
    url: "managers/validationStatus",
  };
  return info;
}

function getYearById() {
  const info = {
    method: "get",
    url: "managers/getYearById/",
  };
  return info;
}

// Period report (old version)
function getPeriodSummary() {
  const info = {
    method: "get",
    url: "managers/monthSummary/",
  };
  return info;
}

// Period report (new version)
function getPeriodReport() {
  const info = {
    method: "get",
    url: "managers/periodReport/",
  };
  return info;
}

function fetchInWaitingPeriodValidation() {
  const info = {
    method: "get",
    url: "managers/validation/inWaitingList/",
  };
  return info;
}

function fetchValidatedPeriod() {
  const info = {
    method: "get",
    url: "managers/validation/residentValidatedList/",
  };
  return info;
}

function updateYearResident() {
  const info = {
    method: "put",
    url: "managers/updateYearResidents/",
  };
  return info;
}

function getYearsWeekIntervals() {
  const info = {
    method: "get",
    url: "managers/years/yearsIntervalsAndWeekTemplatesSummary",
  };
  return info;
}

const exportedObject = {
  findAll,
  create,
  update,
  getYearByToken,
  addYear,
  findResidentYears,
  excel,
  excelAsManager,
  inviteGuest,
  fetchYearManagers,
  UpdateYearResidentRelation,
  fetchPeriod,
  updatePeriod,
  deleteYearResidentRelation,
  getYearById,
  fetchInWaitingPeriodValidation,
  fetchValidatedPeriod,
  getPeriodSummary,
  getPeriodReport,
  updateYearResident,
  getYearsWeekIntervals,
};

export default exportedObject;
