import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useAuth from "../../../../../../hooks/useAuth";
import useAxiosPrivate from "../../../../../../hooks/useAxiosPrivate";
import AuthApi from "../../../../../../services/AuthAPI";
import useNotificationContext from "../../../../../../hooks/useNotificationContext";

// Material UI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Badge from "@mui/material/Badge";

const useStyles = makeStyles((theme) => ({
  linkText: {
    textDecoration: `none`,
  },
}));

const noAuth = [
  {
    groupTitle: "Générale",
    id: "general",
    pages: [
      {
        title: "Notre Service",
        href: "/description",
        disabled: false,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
            />
          </svg>
        ),
      },
    ],
  },
  {
    groupTitle: "Compte",
    id: "account",
    pages: [
      {
        title: "Se connecter",
        href: "/login",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
            />
          </svg>
        ),
      },
      {
        title: "S'enregistrer",
        href: "/connecting",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
            />
          </svg>
        ),
      },
    ],
  },
];

const manager = [
  {
    groupTitle: "Année(s)",
    id: "years",
    pages: [
      {
        title: "Année(s)",
        href: "/manager_years",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={24}
            height={24}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              strokeWidth={2}
              d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
            />
          </svg>
        ),
      },
      {
        title: "AJOUTER",
        href: "/year",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={24}
            height={24}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
        ),
      },
    ],
  },
  {
    groupTitle: "Tableau de bord",
    id: "select-tools",
    pages: [
      {
        title: "Temps réel",
        href: "/realTime",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={24}
            height={24}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
              strokeWidth={2}
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
              strokeWidth={2}
            />
          </svg>
        ),
      },
      {
        title: "Enregistrements",
        href: "/dataTracking",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={24}
            height={24}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              strokeWidth={2}
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
            />
          </svg>
        ),
      },
      {
        title: "Validations",
        href: "/validationStory",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={24}
            height={24}
          >
            <path
              fill-rule="evenodd"
              d="M7.502 6h7.128A3.375 3.375 0 0118 9.375v9.375a3 3 0 003-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 00-.673-.05A3 3 0 0015 1.5h-1.5a3 3 0 00-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6zM13.5 3A1.5 1.5 0 0012 4.5h4.5A1.5 1.5 0 0015 3h-1.5z"
              clip-rule="evenodd"
            />
            <path
              fill-rule="evenodd"
              d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V9.375zm9.586 4.594a.75.75 0 00-1.172-.938l-2.476 3.096-.908-.907a.75.75 0 00-1.06 1.06l1.5 1.5a.75.75 0 001.116-.062l3-3.75z"
              clip-rule="evenodd"
              strokeWidth={1.5}
            />
          </svg>
        ),
      },
      {
        title: "Notifications",
        href: "/managerNotifications",
        disabled: false,
        count: true,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={24}
            height={24}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              strokeWidth={2}
              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
            />
          </svg>
        ),
      },
    ],
  },
  {
    groupTitle: "Agenda",
    id: "agenda",
    disabled: true,
    pages: [
      {
        title: "Calendrier",
        href: "/managerCalendar",
        disabled: false,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              strokeWidth={2}
              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        ),
      },
      {
        title: "Horaires",
        href: "/weekDispatcher",
        disabled: false,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              strokeWidth={2}
              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        ),
      },
      {
        title: "Postes de travail",
        href: "/weekCreator",
        disabled: false,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
            />
          </svg>
        ),
      },
    ],
  },
  ,
  /**{
    groupTitle: "Agenda",
    id: "agenda",
    disabled: true,
    pages: [
      {
        title: "Horaire",
        href: "/weekDispatcher",
        disabled: false,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              strokeWidth={2}
              d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        ),
      },
      {
        title: "Mes postes",
        href: "/weekCreator",
        disabled: false,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
            />
          </svg>
        ),
      },
    ],
  },*/
  {
    groupTitle: "Paramètres",
    id: "settings",
    pages: [
      {
        title: "Compte",
        href: "#",
        disabled: true,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        ),
      },
      {
        title: "Général",
        href: "#",
        disabled: true,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
            />
          </svg>
        ),
      },
    ],
  },
];

const resident = [
  {
    groupTitle: "Générale",
    id: "general",
    pages: [
      {
        title: "Mes horaires",
        href: "/timer",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={24}
            height={24}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              strokeWidth={2}
            />
          </svg>
        ),
      },
      {
        title: "Mes statistiques",
        href: "/residentStatistic",
        selected: true,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={24}
            height={24}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
              strokeWidth={2}
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
              strokeWidth={2}
            />
          </svg>
        ),
      },
      {
        title: "Mes encodages",
        href: "/dataManagement",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={24}
            height={24}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              strokeWidth={2}
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
            />
          </svg>
        ),
      },
      {
        /** 
        title: "Mon agenda",
        href: "/residentCalendar",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={24}
            height={24}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              strokeWidth={2}
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
            />
          </svg>
        ),
      */
      },
    ],
  },
  {
    groupTitle: "Année(s)",
    id: "years",
    pages: [
      {
        title: "Mes années",
        href: "/resident_years",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={24}
            height={24}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              strokeWidth={2}
              d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
            />
          </svg>
        ),
      },
      {
        title: "AJOUTER",
        href: "/search",
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={24}
            height={24}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
        ),
      },
    ],
  },
  {
    groupTitle: "Compte",
    id: "account",
    pages: [
      {
        title: "Notification",
        href: "/residentNotification",
        disabled: false,
        count: true,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={24}
            height={24}
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              strokeWidth={2}
              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
            />
          </svg>
        ),
      },
      {
        title: "Paramètres",
        href: "/residentParameters",
        disabled: false,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={24}
            height={24}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              strokeWidth={2}
            />
          </svg>
        ),
      },
    ],
  },
];

const SidebarNav = ({ onClose, selected, handleSelected }) => {
  const axiosPrivate = useAxiosPrivate();
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const navigate = useNavigate();
  const [menu, setMenu] = useState([]);
  const { notifications } = useNotificationContext();

  // initialization
  const { authentication, setAuthentication } = useAuth();

  const handleLogout = () => {
    const { method, url } = AuthApi.logout();
    axiosPrivate[method](url);
    setAuthentication({});
    navigate("/login");
  };

  const handleSelect = (title) => {
    onClose();
    handleSelected(title);
  };

  useEffect(() => {
    if (authentication) {
      if (authentication?.role === "manager") {
        setMenu(manager);
      }
      if (authentication?.role === "resident") {
        setMenu(resident);
      }
    }
  }, [authentication?.role]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      height="100%"
    >
      <Box padding={2}>
        {authentication.isAuthenticated &&
          menu.map((item, i) => (
            <Box key={i} marginBottom={3}>
              <Typography
                variant="caption"
                color={"text.secondary"}
                sx={{
                  fontWeight: 700,
                  textTransform: "uppercase",
                  marginBottom: 1,
                  display: "block",
                }}
              >
                {item.groupTitle}
              </Typography>
              <Box>
                {item.pages.map((p, i) => (
                  <NavLink
                    to={p.href}
                    className={classes.linkText}
                    key={i}
                    disabled={p.disabled}
                  >
                    <Box marginBottom={1 / 2}>
                      {p.count ? (
                        <Badge
                          badgeContent={notifications?.count}
                          color="primary"
                        >
                          <Button
                            fullWidth
                            disabled={p.disabled}
                            sx={{
                              justifyContent: "flex-start",
                              color: selected === p.title ? "" : "text.primary",
                            }}
                            startIcon={p.icon || null}
                            onClick={() => handleSelect(p.title)}
                          >
                            {p.title}
                          </Button>
                        </Badge>
                      ) : (
                        <Button
                          fullWidth
                          disabled={p.disabled}
                          sx={{
                            justifyContent: "flex-start",
                            color: selected === p.title ? "" : "text.primary",
                          }}
                          startIcon={p.icon || null}
                          onClick={() => handleSelect(p.title)}
                        >
                          {p.title}
                        </Button>
                      )}
                    </Box>
                  </NavLink>
                ))}
              </Box>
            </Box>
          ))}

        {!authentication.isAuthenticated &&
          noAuth.map((item, i) => (
            <Box key={i} marginBottom={3}>
              <Typography
                variant="caption"
                color={"text.secondary"}
                sx={{
                  fontWeight: 700,
                  textTransform: "uppercase",
                  marginBottom: 1,
                  display: "block",
                }}
              >
                {item.groupTitle}
              </Typography>
              <Box>
                {item.pages.map((p, i) => (
                  <NavLink to={p.href} className={classes.linkText}>
                    <Box marginBottom={1 / 2} key={i}>
                      <Button
                        fullWidth
                        disabled={p.disabled}
                        sx={{
                          justifyContent: "flex-start",
                          color: selected === p.title ? "" : "text.primary",
                        }}
                        startIcon={p.icon || null}
                        onClick={() => handleSelect(p.title)}
                      >
                        {p.title}
                      </Button>
                    </Box>
                  </NavLink>
                ))}
              </Box>
            </Box>
          ))}

        {authentication.isAuthenticated && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleLogout}
            startIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
            }
          >
            Se déconnecter
          </Button>
        )}
      </Box>

      {!isMd && (
        <Box>
          {authentication.isAuthenticated && (
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginBottom: "4vh",
              }}
            >
              <Avatar
                sx={{
                  width: 50,
                  height: 50,
                  bgcolor: theme.palette.primary.main,
                }}
              >
                {authentication.firstname &&
                  authentication?.firstname.charAt(0) +
                    authentication?.lastname.charAt(0)}
              </Avatar>
            </Stack>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SidebarNav;
