export const specialityLinks = [
  { value: `anesthesiology`, title: `Anesthésiologie` },
  { value: `dig`, title: `Chirurgie digestive` },
  { value: `general`, title: `Chirurgie générale` },
  { value: `ortho`, title: `Chirurgie orthopédique` },
  { value: `plastic`, title: `Chirurgie plastique` },
  { value: `thor`, title: `Chirurgie thoracique` },
  { value: `uro`, title: `Chirurgie urologique` },
  { value: `vasc`, title: `Chirurgie vasculaire` },
  { value: `gynaeco`, title: `Gynécologie` },
  { value: `internalMedecin`, title: `Médecine interne` },
  { value: `pediatric`, title: `Pédiatrie` },
  { value: `emergency`, title: `Urgences` },
  { value: `intensiveCare`, title: `Soins intensifs` },
];

export const specialityAbreviation = {
  dig: "Chirurgie digestive",
  general: "Chirurgie générale",
  ortho: "Chirurgie orthopédique",
  plastic: "Chirurgie plastique",
  thor: "Chirurgie thoracique",
  uro: "Chirurgie urologique",
  vasc: "Chirurgie vasculaire",
  gynaeco: "Gynécologie",
  internalMedecin: "Médecine interne",
  pediatric: "Pédiatrie",
  anesthesiology: "Anesthésiologie",
  emergency: "Urgences",
  intensiveCare: "Soins intensifs",
};

export const gardeTypeList = {
  callable: "Garde appelable",
  hospital: "Garde sur place",
};

export const absenceTypeList = {
  annualLeave: "Congé annuel",
  paidLeave: "Congé ferrié",
  sickLeave: "Congé maladie",
  paternityLeave: "Congé paternité",
  maternityLeave: "Congé maternité",
  scientificLeave: "Congé scientifique",
  casualLeave: "Congé de circonstance",
  recovery: "Récupération",
};

export const monthList = {
  1: "JANVIER",
  2: "FEVRIER",
  3: "MARS",
  4: "AVRIL",
  5: "MAI",
  6: "JUIN",
  7: "JUILLET",
  8: "AOUT",
  9: "SEPTEMBRE",
  10: "OCTOBRE",
  11: "NOVEMBRE",
  12: "DECEMBRE",
};

export const jobList = {
  "medical supervisor": "Maître de stage",
  doctor: "Médecin",
  "human resources": "Ressources humaines",
};

export const warningList = {
  minLimit: "Dépassement du maximum autorisé",
  maxLimit: "Dépassement du maximum légal",
  overruns: "Dépassement multiple",
  smoothing: "Lissage sur 13 semaines",
};
